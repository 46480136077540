import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
import bus from "@/plugins/bus";
export default {
  name: "orgAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      formModel: {
        orgId: null,
        orgCode: null,
        orgName: null
      },
      formRules: {
        orgCode: [{
          required: true,
          message: '请输入机构编码',
          trigger: 'blur'
        }, {
          max: 32,
          message: '机构编码长度不能超过32',
          trigger: 'blur'
        }],
        orgName: [{
          required: true,
          message: '请输入机构名称',
          trigger: 'blur'
        }, {
          max: 64,
          message: '机构名称长度不能超过64',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.uac.org.update(this.formModel).then(callBack);
          } else {
            this.$api.uac.org.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.orgId) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.uac.org.detail(this.$route.query.orgId).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
        } else if (res.code === 101602) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};